import Input from '@/interfaces/inputs/Input'
import Textarea from '@/interfaces/inputs/Textarea'
import FormElement from '@/interfaces/form/FormElement'

const name = (): Input => ({
  id: 'name',
  label: 'Nombre',
  type: 'text',
  value: null,
  required: true
})

const description = (): Textarea => ({
  id: 'description',
  label: 'Descripción',
  value: null,
  required: true
})

export const formElements = (): FormElement[] => [
  {
    type: 'input',
    data: name()
  },
  {
    type: 'textarea',
    data: description()
  }
]
