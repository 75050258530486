<template>
  <div class="p-4" v-if="showForm">
    {{ titleForm }}
    <br />
    <br />
    <BaseForm
      :formElements="formElements"
      @form:onSubmit="value => saveStudy(value)"
      :isLoading="isLoading"
    >
      <template #elements>
        <BaseFormElement
          :key="key"
          v-for="(element, key) in formElements"
          :elements="formElements"
          :element="element"
          v-model="element.data.value"
        >
          <template #after>
            <component :is="element.after" />
          </template>
        </BaseFormElement>
      </template>
    </BaseForm>
  </div>

  <div v-else>
    Loading
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import BaseForm from '@/components/form/BaseForm.vue'
import BaseFormElement from '@/components/form/BaseFormElement.vue'
import { formElements as CourseFormElements } from '@/config/CourseFormElements.ts'
import syncValues from '@/utils/syncValues.ts'

// Courses store
import { COURSES_STORE_NAME } from '@/store/modules/courses'
import {
  CREATE_COURSE,
  UPDATE_COURSE
} from '@/store/modules/courses/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

export default {
  components: { BaseForm, BaseFormElement },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const courses = computed(() => store.getters[COURSES_STORE_NAME + '/data'])
    const formElements = ref(CourseFormElements())

    const isNewCourse = computed(() => props.id === null)
    const titleForm = computed(() =>
      isNewCourse.value ? 'Nuevo curso' : 'Editar curso'
    )
    const existCourse = computed(() => courses?.value?.[props.id])
    const showForm = computed(() => isNewCourse.value || existCourse.value)

    const isLoading = computed(
      () => store.getters[COURSES_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[COURSES_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[COURSES_STORE_NAME + '/isReady']
    )

    async function saveStudy(data) {
      if (props.id) {
        data.id = props.id
      }
      const action = isNewCourse.value ? CREATE_COURSE : UPDATE_COURSE
      await store.dispatch(COURSES_STORE_NAME + '/' + action, data)
      if (isReady.value) {
        router.push({ name: 'courses' })

        pushNotification({
          title: isNewCourse.value
            ? 'Creado'
            : 'Actualizado' + ' correctamente!',
          desciption: 'Tu curso se ha guardado correctamente ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    watch(courses, value => {
      if (value && props.id) {
        if (!existCourse.value) {
          router.push({ name: 'courses' })
        }
        if (existCourse.value) {
          formElements.value = syncValues(formElements.value, existCourse.value)
        }
      }
    })

    if (existCourse.value) {
      formElements.value = syncValues(formElements.value, existCourse.value)
    }

    return { formElements, isLoading, saveStudy, titleForm, showForm }
  }
}
</script>
